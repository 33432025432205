import React from "react";
import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import LargeSectionHeader from "../components/large-section-header";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 50
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          _rawBody
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  
  // Using this func instead of filtering in postNodes declaration should fix issue
  // with displaying future posts without building first (NEED TEST)
  const filterOutFuturePostsOrMissingSlugs = (posts) => {
    return mapEdgesToNodes(posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
  }

  const postNodes = (data || {}).posts
    ? data.posts
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        {filterOutFuturePostsOrMissingSlugs(postNodes) && (
          <div>
            <BlogPostPreviewGrid
              nodes={filterOutFuturePostsOrMissingSlugs(postNodes).slice(0, 6)}
              topStoryFrequency={4}
              maxNumTopStory={2}
            />
            <LargeSectionHeader>More</LargeSectionHeader>
            <BlogPostPreviewList
              nodes={filterOutFuturePostsOrMissingSlugs(postNodes).slice(6)}
              isInList={true}
            />
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
